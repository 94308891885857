<script setup>
import { ref } from 'vue';
import { useAccountStore } from '@stores/account';

const props = defineProps({
    elementId: {
        type: Number,
        required: true,
    },

    elementType: {
        type: String,
        required: true,
    },

    followed: {
        type: Boolean,
        required: true,
    },
});

const accountStore = useAccountStore();

const loading = ref(false);
const following = ref(props.followed);

const followElement = async(id, type) => {
    loading.value = true;

    await accountStore.followElement(id, type);

    following.value = true;
    loading.value = false;
};

const unfollowElement = async(id, type) => {
    loading.value = true;

    await accountStore.unfollowElement(id, type);

    following.value = false;
    loading.value = false;
};

const toggleFollow = () => {
    following.value ?
        unfollowElement(props.elementId, props.elementType) :
        followElement(props.elementId, props.elementType);
};
</script>

<template>
    <button type="button" @click="toggleFollow">
        <slot :following="following" :loading="loading"></slot>
    </button>
</template>
