// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';
import '@/scss/cp.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-as-nav-for';
import 'flickity-bg-lazyload';
import 'flickity-imagesloaded';
import 'flickity-sync';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

import lax from 'lax.js';

import './utils/table-of-content';

// ================================================
// SUI Components
// ================================================


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import apolloClient and create a provider
import { createApolloProvider } from '@vue/apollo-option';
import apolloClient from './config/apollo';

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
});

// Pinia
import { createPinia } from 'pinia';

const pinia = createPinia();

// Import all globally-available components here. Be responsible please.
import {
    Dialog,
    DialogDescription,
    DialogPanel,
    DialogTitle,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';

import SplideCarousel from '@components/SplideCarousel.vue';

import {
    SplideTrack,
    SplideSlide,
} from '@splidejs/vue-splide';

import {
    ContentFeed,
    CustomiseFeed,
    DialogTrigger,
    Dropdown,
    EnlargeGallery,
    FieldGuide,
    FieldGuideMap,
    FilteredElements,
    FollowButton,
    GlobeMap,
    LaxScrollAnimation,
    ListingCard,
    LittlePic,
    MembershipPopup,
    Pagination,
    PellEditor,
} from '@components';

import {
    StripeElements,
    StripeElementsButton,
    StripeElementsCard,
    StripeElementsCardNumber,
    StripeElementsCvc,
    StripeElementsExpiry,
    StripeElementsSubmitOnUpdate,
} from '@components/StripeElements';

const app = createApp({
    delimiters: ['${', '}'],

    components: {
        ContentFeed,
        CustomiseFeed,
        DialogTrigger,
        Dropdown,
        EnlargeGallery,
        FieldGuide,
        FieldGuideMap,
        FilteredElements,
        FollowButton,
        GlobeMap,
        ListingCard,
        LittlePic,
        MembershipPopup,
        Pagination,
        PellEditor,
        SplideCarousel,
        SplideCarouselTrack: SplideTrack,
        SplideCarouselSlide: SplideSlide,
        StripeElements,
        StripeElementsButton,
        StripeElementsCard,
        StripeElementsCardNumber,
        StripeElementsCvc,
        StripeElementsExpiry,
        StripeElementsSubmitOnUpdate,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiDialog: Dialog,
        HuiDialogPanel: DialogPanel,
        HuiDialogTitle: DialogTitle,
        HuiDialogDescription: DialogDescription,
        HuiTransitionRoot: TransitionRoot,
        HuiTransitionChild: TransitionChild,
        HuiMenu: Menu,
        HuiMenuButton: MenuButton,
        HuiMenuItems: MenuItems,
        HuiMenuItem: MenuItem,
    },

    provide() {
        lax.init();

        lax.addDriver('scrollY', () => {
            return window.scrollY;
        });

        return {
            lax,
        };
    },

    data() {
        return {
            mainMenuOpen: false,
        };
    },

    mounted() {

    },
});

app.use(apolloProvider);
app.use(pinia);

app.mount('#app');
